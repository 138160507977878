<template>
  <!-- 倾听详情 -->
  <div id="app" style="background: #f4f4f4">
    <div class="harken-con cson-container clearfix">
      <div class="harken-le">
        <div class="harken-content">
          <div class="shead">
            <div class="detail-tit">{{ sugDetail.questionTitle }}</div>
            <div class="list-container">
              <div class="list">
                <div class="list-box detail-box">
                  <div class="tbody">
                    <div class="else">
                      <h2>
                        <router-link
                          :to="`/userPage?userId=${sugDetail.userId}`"
                        >
                          <img :src="sugDetail.avatar" />
                        </router-link>
                        {{ sugDetail.username }}
                      </h2>
                      <p class="time">提问于 {{ sugDetail.createTime }}</p>

                      <div
                        :class="[
                          'tags',
                          sugDetail.status && sugDetail.status.value != 1
                            ? 'unpublished'
                            : '',
                        ]"
                      >
                        {{ sugDetail.status && sugDetail.status.name }}
                      </div>
                    </div>
                    <div class="words-tit">【建议描述】</div>
                    <div class="words">
                      {{ sugDetail.questionDescribe }}
                    </div>
                    <template
                      v-if="
                        sugDetail.questionSolution &&
                        sugDetail.questionSolution != ''
                      "
                    >
                      <div class="words-tit">【建议解决方案】</div>
                      <div class="words">
                        {{ sugDetail.questionSolution }}
                      </div>
                    </template>

                    <div class="imageContainer">
                      <el-image
                        v-for="(image, index) in sugDetail.printscreen"
                        style="width: 100px; height: 100px"
                        :src="image"
                        :key="index"
                        @click="
                          showIndex = index;
                          showViewer = true;
                        "
                      />
                    </div>
                    <el-image-viewer
                      v-if="showViewer"
                      :initial-index="showIndex"
                      :url-list="sugDetail.printscreen"
                      :on-close="closeViewer"
                    />
                    <div style="text-align: right; line-height: 16px">
                      <div
                        style="display: inline-block; cursor: pointer"
                        @click="likeComments(0)"
                      >
                        <img
                          src="@/assets/img/isgood.png"
                          style="width: 16px; vertical-align: top"
                        />
                        {{ sugDetail.likeNums }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="commentArea officialAnswer"
              v-if="sugDetail.questionAnswer"
            >
              <div class="offcialReply">
                <img
                  class="teacherAvatar"
                  src="@/assets/images/story/tx2.png"
                  alt=""
                /><span>CSON 【官方回复】</span>
              </div>
              <div class="containers" style="padding: 20px 0">
                {{ sugDetail.questionAnswer }}
              </div>
            </div>
            <div class="commentArea yours" style="position: relative">
              <div
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  text-align: center;
                  line-height: 230px;
                  z-index: 99999;
                  background: rgba(0, 0, 0, 0.2);
                  color: #fff;
                "
                v-if="!userInfo || !userInfo.id"
              >
                请先
                <router-link to="/login">
                  <el-button type="primary" size="mini" style="margin: 8px"
                    >登录</el-button
                  ></router-link
                >后发表评论
              </div>
              <el-row>
                <el-col :span="2">
                  <img
                    class="userInfo avatar"
                    v-if="userInfo && userInfo.id"
                    :src="userInfo.avatar"
                    alt=""
                  />
                  <img
                    class="userInfo avatar"
                    v-else
                    src="@/assets/company/userLogo.png"
                    alt=""
                  />
                </el-col>
                <el-col :span="22">
                  <el-row>
                    <el-input
                      type="textarea"
                      v-model="comments"
                      :rows="3"
                      placeholder="写下你的评论..."
                      ref="activityCom"
                    >
                    </el-input>
                  </el-row>
                  <el-row style="margin-top: 12px">
                    <el-col :span="6">
                      <emotions @chooseEmotion="handleEmotion"></emotions>
                    </el-col>
                    <el-col :offset="14" :span="4" style="text-align: right">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="publishComment"
                        :disabled="!userInfo || !userInfo.id"
                        >发表评论</el-button
                      >
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <comments
              v-if="sugDetail.commentNums || sugDetail.commentNums == 0"
              :type="3"
              :comment-total="sugDetail.commentNums"
            ></comments>
          </div>
        </div>
      </div>
      <div class="harken-rg" style="margin-top: 20px">
        <SideAdvertisement />
      </div>
    </div>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { getSugDetail, publishComment } from "@/service/harken";
import { mapState } from "vuex";
import emotions from "@/utils/emotions";
import comments from "@/components/comments/index";
import SideAdvertisement from "@/components/sideAdvertisement";
export default {
  name: "HarkenDetail",
  components: { emotions, ElImageViewer, comments, SideAdvertisement },
  data() {
    return {
      sugDetail: {},
      sugList: [],
      comments: "",
      currentPage: 1,
      sugListTotal: 0,
      replyVisible: false,
      showViewer: false,
      showIndex: 0,
    };
  },
  filters: {
    formatNum: function (value) {
      if (!value) return "";
      return value.substring(0, 2);
    },
  },
  mounted() {
    this.getSugDetail();
  },
  watch: {
    $route() {
      this.getSugDetail();
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    closeViewer() {
      this.showViewer = false;
    },
    handleEmotion(emotion) {
      this.comments = this.comments + emotion;
    },
    showReply() {
      this.replyVisible = !this.replyVisible;
    },
    getSugDetail() {
      getSugDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.sugDetail = res.result;
          document.title = `${res.result.questionTitle} - 专注北美算法面试 - CSON`;
        }
      });
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      const data = {
        type: false,
        parentId: commentId,
        superId: this.$route.query.id,
        superType: 3,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.closeAll();
          this.$message.success("点赞成功！");
          this.getSugDetail();
        } else {
          this.$message.closeAll();
          this.$message.info("已点过赞");
        }
      });
    },
    publishComment() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      if (!this.comments || this.comments == "") {
        return this.$message.error("请输入评论内容！");
      }
      const data = {
        content: this.comments,
        type: true,
        parentId: 0,
        superId: this.$route.query.id,
        superType: 3,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.comments = "";
          this.$message.success("评论成功！");
          this.getSugDetail();
          this.$bus.$emit("updateCommentList", "update");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.shead {
  margin-top: 20px;
}
.harken-rg {
  margin-top: 72px;
}
.imgs img {
  margin-bottom: 12px;
}
.unpublished {
  background: #34495e !important;
}
.commentArea,
.commentsBelow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px 48px;
  margin: 12px 0;
}
.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.popover-menu {
  min-width: 95px;
  margin-top: 0 !important;
}

.popover-menu .popper__arrow {
  display: none;
}
.commentArea,
.commentsBelow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px 48px;
  margin: 12px 0;
}
.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.noComments {
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
}
.team-cell-desc {
  font-size: 14px;
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  margin-bottom: 4px;
  padding-left: 44px;
}

.team-cell-desc .el-link {
  margin-left: 30px;
}

.team-cell .el-link {
  color: #fa6400;
}

.team-cell__bd {
  position: relative;
}

.team-cell-info .comment-user {
  color: #34495e;
  padding-left: 44px;
}
.childComment .team-cell-info .comment-user {
  color: #34495e;
  padding-left: 30px;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 15px;
  vertical-align: top;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}

.team-cell__footer {
  padding: 15px 20px 0;
}

.team-cell__ed {
  float: left;
}

.team-cell__block {
  position: relative;
  margin-left: 44px;
}

.team-cell__bts {
  text-align: right;
  padding: 10px 0;
}

.team-cell__bts .btn {
  font-size: 14px;
  border-radius: 2px;
  width: 64px;
  line-height: 28px;
  border-color: transparent;
  margin-left: 10px;
}
.panel-title {
  position: relative;
  font-weight: 500;
  color: #34495e;
  font-size: 14px;
  padding-left: 11px;
  line-height: 20px;
}

.panel-title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}

.team-cell-info .comment-user {
  color: #34495e;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 25px;
  font-size: 12px;
  display: inline-block;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}
.team-cell:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.team-cell {
  padding: 24px 0;
}
.secondCommentList {
  width: 95%;
  margin: 12px 0;
  margin-left: 5%;
}
.childComment {
  padding: 6px;
  line-height: 24px;
  .avatar {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 50%;
    vertical-align: top;
  }
  .commentContent {
    color: #222;
    margin-top: 4px;
  }
}

.comment-user div {
  font-size: 12px;
  line-height: 20px;
  margin-left: 12px;
  cursor: pointer;
  display: inline-block;
}
.comment-user div i {
  vertical-align: top;
}

.team-cell-title {
  color: #34495e;
  line-height: 36px;
  font-size: 16px;
  margin-bottom: 4px;
}
.offcialReply {
  line-height: 30px;
  .teacherAvatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 8px;
  }
}
</style>
